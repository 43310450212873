
import Vue from "vue";
import rules from "@/services/helpers/validation-rules";
import store from "@/store";
import FileUploader from "@/components/form-components/FileUploader.vue";
import { mask } from "vue-the-mask";
import MultiplePhonePicker from "@/components/form-components/MultiplePhonePicker.vue";
import API from "@/api/API";
import DatePicker from "@/components/form-components/DatePicker.vue";
import CreateUser from "@/modules/users/views/createNEW.vue";
import LiveSearch from "@/components/form-components/LiveSearch.vue";

export default Vue.extend({
  name: "Create",

  components: {
    LiveSearch,
    CreateUser,
    DatePicker,
    MultiplePhonePicker,
    FileUploader
  },

  directives: { mask },

  data: () => ({
    rules,
    model: {
      birthdate: "" as string,
      is_active: true as boolean,
      phones: [""] as Array<string>,
      services: [] as Array<any>,
      work_positions: [] as Array<any>,
      certificates: [{} as any] as Array<any>
    } as any,
    errorMessages: {} as { [value: string]: Array<string> },
    selects: {
      genders: [
        { value: "f", text: "F" },
        { value: "m", text: "M" }
      ] as Array<SelectComponentInterface>,
      centers: [] as Array<SelectComponentInterface>,
      users: [] as Array<SelectComponentInterface>,
      workPositions: [] as Array<SelectComponentInterface>,
      subdivisions: [] as Array<SelectComponentInterface>,
      branches: [] as Array<SelectComponentInterface>
    } as any,
    certificateTypes: [] as Array<SelectComponentInterface>,
    allowedCertificatesRoles: [
      "specialist",
      "expert",
      "specialist_ata",
      "specialist_aaij"
    ] as Array<string>,
    showCreateUser: false as any,
    user: null as any
  }),

  computed: {
    maxDate(): any {
      return this.$moment().format("YYYY-MM-DD");
    },
    showCertificates() {
      const rolesCertificates = [] as Array<any>;

      for (const item of this.selects.workPositions) {
        if (this.allowedCertificatesRoles.includes(item.text)) {
          rolesCertificates.push(item.value);
        }
      }

      return this.model.work_positions.some((item: any) =>
        rolesCertificates.includes(item)
      );
    }
  },

  async beforeRouteEnter(to, from, next) {
    try {
      const workPositions = await API.workPositions().getList();
      const branches = await API.branches().getList();
      const users = await API.users().getEmployeesList();
      const certificateTypes = await API.employees().getCertificatesList();


      next(vm => {
        vm.selects.workPositions = workPositions;
        vm.selects.branches = branches.items;
        vm.selects.users = users;
        vm.selects.certificateTypes = certificateTypes;
      });
    } catch (e) {
      await store.dispatch("alert/showError", e.message);
    }
  },

  methods: {
    async submit(): Promise<void> {
      try {
        if ((this.$refs.form as Vue).validate()) {
          const model = { ...this.model };

          if (!this.showCertificates) {
            model.certificates = [];
          }

          await this.$API.employees().create(model);
          await this.$router.push("/employees");
          await this.$store.dispatch(
            "alert/showSuccess",
            this.$t("global_alert.successful_create")
          );
        }
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;

          setTimeout(() => {
            this.errorMessages = {};
          }, 4000);
        }
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async getListByBranch(): Promise<void> {
      try {
        this.selects.subdivisions = await this.$API
          .subdivisions()
          .getListByBranch(this.model.branch_id);

        if (
          !this.selects.subdivisions.some(
            (item: any) => item.value === this.model.subdivision_id
          )
        ) {
          delete this.model.subdivision_id;
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    removeCertificate(index: number): void {
      this.model.certificates.splice(index, 1);
    },
    addCertificate(): void {
      this.model.certificates.push({});
    },
    setUser(user: any): void {
      this.model.user_id = user.value;
      this.user = user;
    },
    async searchUser(searchValue: string): Promise<any> {
      try {
        return {
          items: await this.$API.users().liveSearchEmployees({ searchValue })
        };
      } catch (e) {
        await this.$store.dispatch("alert/showError");
      }
    },
    toggleCreateUser(): void {
      this.showCreateUser = !this.showCreateUser;
    }
  }
});
